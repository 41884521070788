






































































import { Component, Prop } from "vue-property-decorator";
import _ from "lodash";

import AppVue from "@/AppVue.vue";
import { ApplicationModel, ApplicationLookups } from "@/core/webapi";
import { InputTextareaCpt } from "@/core/components/common";

@Component({
  components: {
    InputTextareaCpt,
  },
})
export default class Step5bCpt extends AppVue {
  @Prop() model: ApplicationModel;
  @Prop() lookups: ApplicationLookups;

  get trainingSessionErrors() {
    return this.getError("trainingSessionId");
  }

  needHoodie = false;

  private getError(propName: string) {
    const errors = ((this.model?.modelState as any)[propName] as any[]) || [];
    return errors[0] ?? "";
  }

  onNeedHoodieChange(value: boolean) {
    if (!value) {
      this.model.hoodieSizeId = null;
    } else if (!this.model.hoodieSizeId && this.lookups.hoodieSizes) {
      this.model.hoodieSizeId = _.first(this.lookups.hoodieSizes)?.id ?? null;
    }
  }
}
